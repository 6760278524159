import request from '@/utils/request'

class Api {
    // 盲盒-获取活动信息
    getBlindBoxData(data) {
        return request({
            url: '/v1.0/turntable/luckdraw',
            method: 'get',
            params: data
        })
    }
    // 盲盒-获取全部商品
    getBlindBoxWinningRecord(params) {
        return request({
            url: '/v1.0/turntable/prizeList',
            method: 'get',
            params
        })
    }
    // 盲盒-获取中奖记录
    getBlindBoxPrizeLog(params) {
        return request({
            url: '/v1.0/turntable/prizeLog',
            method: 'get',
            params
        })
    }
    // 盲盒-拆盲盒
    openBox(data) {
        return request({
            url: '/v1.0/turntable/drawLottery',
            method: 'get',
            params: data
        })
    }
    // 盲盒抽奖记录
    pitchInLog(data) {
        return request({
            url: '/v1.0/turntable/pitchInLog',
            method: 'get',
            params: data
        })
    }
    // // 盲盒-规则
    // getRule(data) {
    //     return request({
    //         url: '/v1.0/cms/content',
    //         method: 'get',
    //         params: data
    //     })
    // }
    // 盲盒-分享增加次数
    shareAddNum(data) {
        return request({
            url: '/v1.0/turntable/luckShare',
            method: 'post',
            params: data
        })
    }
    // // 增加曝光
    // addExposure(data) {
    //     return request({
    //         url: '/v1.0/samecity/addExposeCount',
    //         method: 'post',
    //         params: data
    //     })
    // }
}
export default Api